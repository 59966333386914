import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <>
  {/* header */}
  <header className="site-header" id="header">
    <nav className="navbar navbar-expand-lg transparent-bg static-nav">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img
            src="assets/img/logo.png"
            alt="logo"
            className="logo-default"
          />
          <img src="assets/img/logo.png" alt="logo" className="logo-scrolled" />
        </a>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item position-relative">
              <a className="nav-link active pagescroll" href="#home">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link pagescroll" href="#our-process">
                About
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link pagescroll" href="#our-apps">
                Service
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link pagescroll" href="#our-testimonial">
                Testimonial
              </a>
            </li>
           <li className="nav-item">
              <a className="nav-link pagescroll" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/*side menu open button*/}
      <a
        href="javascript:void(0)"
        className="d-inline-block sidemenu_btn"
        id="sidemenu_toggle"
      >
        <span /> <span /> <span />
      </a>
    </nav>
    {/* side menu */}
    <div className="side-menu opacity-0 gradient-bg">
      <div className="overlay" />
      <div className="inner-wrapper">
        <span className="btn-close btn-close-no-padding" id="btn_sideNavClose">
          <i />
          <i />
        </span>
        <nav className="side-nav w-100">
          <ul className="navbar-nav">
            <li className="nav-item position-relative">
              <a className="nav-link pagescroll" href="#home">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link pagescroll" href="#our-process">
                About
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link pagescroll" href="#our-apps">
                Service
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link pagescroll" href="#our-testimonial">
                Testimonial
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link pagescroll" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </nav>
        <div className="side-footer w-100">
          <ul className="social-icons-simple white top40">
            <li>
              <a href="https://www.facebook.com/dgtechie" target="_blank"  rel="noreferrer">
                <i className="fab fa-facebook-f" />{" "}
              </a>{" "}
            </li>
            <li>
              <a href="https://twitter.com/dgtechieindia" target="_blank"  rel="noreferrer">
                <i className="fab fa-twitter" />{" "}
              </a>{" "}
            </li>
            <li>
              <a href="https://www.instagram.com/digital.techie" target="_blank"  rel="noreferrer">
                <i className="fab fa-instagram" />{" "}
              </a>{" "}
            </li>
          </ul>
          <p className="whitecolor">
            © <span id="year" /> Digital Techie
          </p>
        </div>
      </div>
    </div>
    <div id="close_side_menu" className="tooltip" />
    {/* End side menu */}
  </header>
  {/* header */}
  {/*Main Slider*/}
  <section id="home">
    <h2 className="d-none">heading</h2>
    <div
      id="revo_main_wrapper"
      className="rev_slider_wrapper fullwidthbanner-container"
    >
      <div
        id="rev_one_page"
        className="rev_slider fullwidthabanner"
        style={{ display: "none" }}
        data-version="5.4.1"
      >
        <ul>
          {/* SLIDE  */}
          <li
            data-index="rs-01"
            data-transition="fade"
            data-slotamount="default"
            data-easein="Power3.easeInOut"
            data-easeout="Power3.easeInOut"
            data-masterspeed={2000}
            data-fsmasterspeed={1500}
            className="banner-overlay"
          >
            {/* MAIN IMAGE */}
            <div className="overlay overlay-dark opacity-6" />
            <img
              src="assets/images/banner4.jpg"
              alt=""
              data-bgposition="center center"
              data-bgfit="cover"
              data-bgrepeat="no-repeat"
              className="rev-slidebg"
              data-bgparallax={10}
              data-no-retina=""
            />
            {/* LAYER NR. 1 */}
            <div
              className="tp-caption tp-resizeme z-index-1 font-light2 text-capitalize whitecolor"
              data-x="['center','center','center','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['-100','-100','-140','-140']"
              data-fontsize="['48','48','46','45']"
              data-whitespace="nowrap"
              data-responsive_offset="on"
              data-width="['none','none','none','none']"
              data-type="text"
              data-textalign="['center','center','center','center']"
              data-transform_idle="o:1;"
              data-transform_in="x:-50px;opacity:0;s:2000;e:Power3.easeOut;"
              data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
              data-start={1000}
              data-splitin="none"
              data-splitout="none"
            >
              simple but
            </div>
            <div
              className="tp-caption tp-resizeme font-bold z-index-2 text-capitalize whitecolor"
              data-x="['center','center','center','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['-30','-30','-70','-70']"
              data-fontsize="['48','48','46','45']"
              data-whitespace="nowrap"
              data-responsive_offset="on"
              data-width="['none','none','none','none']"
              data-type="text"
              data-textalign="['center','center','center','center']"
              data-transform_idle="o:1;"
              data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;s:1500;e:Power3.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
              data-start={1200}
              data-splitin="none"
              data-splitout="none"
            >
              more powerful
            </div>
            <div
              className="tp-caption tp-resizeme z-index-3 font-xlight whitecolor text-capitalize"
              data-x="['center','center','center','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['40','40','0','0']"
              data-fontsize="['48','48','46','45']"
              data-whitespace="nowrap"
              data-responsive_offset="on"
              data-width="['none','none','none','none']"
              data-type="text"
              data-textalign="['center','center','center','center']"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-start={1000}
              data-splitin="none"
              data-splitout="none"
            >
              innovate technology
            </div>
            <div
              className="tp-caption"
              data-x="['center','center','center','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['120','120','100','100']"
              data-textalign="['center','center','center','center']"
              data-whitespace="nowrap"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:1500;e:Power4.easeInOut;"
              data-transform_out="s:900;e:Power2.easeInOut;s:900;e:Power2.easeInOut;"
              data-frames='[{"delay":700,"speed":2000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:40px;","to":"o:1;fb:0;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"200","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;fb:0;"}]'
            >
            </div>
          </li>
          {/* SLIDE 2  */}
          <li
            data-index="rs-02"
            data-transition="fade"
            data-slotamount="default"
            data-easein="Power3.easeInOut"
            data-easeout="Power3.easeInOut"
            data-masterspeed={2000}
            data-fsmasterspeed={1500}
            className="banner-overlay"
          >
            {/* MAIN IMAGE */}
            <div className="overlay overlay-dark opacity-6" />
            <img
              src="assets/images/banner-single-2.jpg"
              alt=""
              data-bgposition="center center"
              data-bgfit="cover"
              data-bgrepeat="no-repeat"
              className="rev-slidebg"
              data-bgparallax={10}
              data-no-retina=""
            />
            {/* LAYER NR. 2 */}
            <div
              className="tp-caption tp-resizeme z-index-5 font-xlight whitecolor text-capitalize"
              data-x="['center','center','center','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['-80','-80','-60','-60']"
              data-fontsize="['32','32','30','28']"
              data-whitespace="nowrap"
              data-responsive_offset="on"
              data-width="['none','none','none','none']"
              data-type="text"
              data-textalign="['right','right','right','center']"
              data-transform_idle="o:1;"
              data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;s:1500;e:Power3.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
              data-start={1200}
              data-splitin="none"
              data-splitout="none"
              style={{ letterSpacing: 1 }}
            >
              we're a Development company
            </div>
            <div
              className="tp-caption tp-resizeme z-index-6 font-normal whitecolor text-capitalize"
              data-x="['center','center','center','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['-20','-20','-10','-10']"
              data-fontsize="['48','48','46','45']"
              data-whitespace="nowrap"
              data-responsive_offset="on"
              data-width="['none','none','none','none']"
              data-type="text"
              data-textalign="['right','right','right','center']"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-start={1000}
              data-splitin="none"
              data-splitout="none"
            >
              believes in
            </div>
            <div
              className="tp-caption tp-resizeme z-index-7 font-bold whitecolor text-capitalize"
              data-x="['center','center','center','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['45','45','50','50']"
              data-fontsize="['48','48','46','45']"
              data-whitespace="nowrap"
              data-responsive_offset="on"
              data-width="['none','none','none','none']"
              data-type="text"
              data-textalign="['right','right','right','center']"
              data-transform_idle="o:1;"
              data-transform_in="z:0;rX:0deg;rY:0;rZ:0;sX:2;sY:2;skX:0;skY:0;opacity:0;s:1000;e:Power2.easeOut;"
              data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
              data-start={1500}
              data-splitin="none"
              data-splitout="none"
              style={{ lineHeight: 30 }}
            >
              an innovation
            </div>
            <div
              className="tp-caption"
              data-x="['center','center','center','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['120','120','120','120']"
              data-textalign="['center','center','center','center']"
              data-whitespace="nowrap"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:1500;e:Power4.easeInOut;"
              data-transform_out="s:900;e:Power2.easeInOut;s:900;e:Power2.easeInOut;"
              data-type="button"
              data-start={2000}
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
            >
            </div>
          </li>
          {/* SLIDE 3  */}
          <li
            data-index="rs-03"
            data-transition="fade"
            data-slotamount="default"
            data-easein="Power3.easeInOut"
            data-easeout="Power3.easeInOut"
            data-masterspeed={2000}
            data-fsmasterspeed={1500}
            className="banner-overlay"
          >
            {/* MAIN IMAGE */}
            <div className="overlay overlay-dark opacity-6" />
            <img
              src="assets/images/banner-light1-2.jpg"
              alt=""
              data-bgposition="center center"
              data-bgfit="cover"
              data-bgrepeat="no-repeat"
              className="rev-slidebg"
              data-bgparallax={10}
              data-no-retina=""
            />
            {/* LAYER NR. 3 */}
            <div
              className="tp-caption tp-resizeme z-index-8 font-light2 whitecolor text-capitalize"
              data-x="['center','center','center','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['-100','-100','-140','-140']"
              data-fontsize="['48','48','46','45']"
              data-whitespace="nowrap"
              data-responsive_offset="on"
              data-width="['none','none','none','none']"
              data-type="text"
              data-textalign="['center','center','center','center']"
              data-transform_idle="o:1;"
              data-transform_in="x:-50px;opacity:0;s:2000;e:Power3.easeOut;"
              data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
              data-start={1000}
              data-splitin="none"
              data-splitout="none"
            >
              Passionate coders
            </div>
            <div
              className="tp-caption tp-resizeme z-index-9 font-bold whitecolor text-capitalize"
              data-x="['center','center','center','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['-30','-30','-70','-70']"
              data-fontsize="['48','48','46','45']"
              data-whitespace="nowrap"
              data-responsive_offset="on"
              data-width="['none','none','none','none']"
              data-type="text"
              data-textalign="['center','center','center','center']"
              data-transform_idle="o:1;"
              data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;s:1500;e:Power3.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
              data-start={1200}
              data-splitin="none"
              data-splitout="none"
              style={{ letterSpacing: 1 }}
            >
              ultimate results
            </div>
            <div
              className="tp-caption tp-resizeme z-index-10 font-xlight whitecolor text-capitalize"
              data-x="['center','center','center','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['40','40','0','0']"
              data-fontsize="['48','48','46','45']"
              data-whitespace="nowrap"
              data-responsive_offset="on"
              data-width="['none','none','none','none']"
              data-type="text"
              data-textalign="['center','center','center','center']"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-start={1000}
              data-splitin="none"
              data-splitout="none"
            >
              Turning codes into reality
            </div>
            <div
              className="tp-caption"
              data-x="['center','center','center','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['120','120','100','100']"
              data-textalign="['center','center','center','center']"
              data-whitespace="nowrap"
              data-type="button"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:1500;e:Power4.easeInOut;"
              data-transform_out="s:900;e:Power2.easeInOut;s:900;e:Power2.easeInOut;"
              data-start={2000}
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
            >
            </div>
          </li>
          <li
            data-index="rs-04"
            data-transition="fade"
            data-slotamount="default"
            data-easein="Power3.easeInOut"
            data-easeout="Power3.easeInOut"
            data-masterspeed={2000}
            data-fsmasterspeed={1500}
            className="banner-overlay"
          >
            {/* MAIN IMAGE */}
            <div className="overlay overlay-dark opacity-6" />
            <img
              src="assets/images/banner2.jpg"
              alt=""
              data-bgposition="center center"
              data-bgfit="cover"
              data-bgrepeat="no-repeat"
              className="rev-slidebg"
              data-bgparallax={10}
              data-no-retina=""
            />
            {/* LAYER NR. 3 */}
            <div
              className="tp-caption tp-resizeme z-index-8 font-light2 whitecolor text-capitalize"
              data-x="['center','center','center','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['-100','-100','-140','-140']"
              data-fontsize="['48','48','46','45']"
              data-whitespace="nowrap"
              data-responsive_offset="on"
              data-width="['none','none','none','none']"
              data-type="text"
              data-textalign="['center','center','center','center']"
              data-transform_idle="o:1;"
              data-transform_in="x:-50px;opacity:0;s:2000;e:Power3.easeOut;"
              data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
              data-start={1000}
              data-splitin="none"
              data-splitout="none"
            >
              The next generation 
            </div>
            <div
              className="tp-caption tp-resizeme z-index-9 font-bold whitecolor text-capitalize"
              data-x="['center','center','center','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['-30','-30','-70','-70']"
              data-fontsize="['48','48','46','45']"
              data-whitespace="nowrap"
              data-responsive_offset="on"
              data-width="['none','none','none','none']"
              data-type="text"
              data-textalign="['center','center','center','center']"
              data-transform_idle="o:1;"
              data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;s:1500;e:Power3.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
              data-start={1200}
              data-splitin="none"
              data-splitout="none"
              style={{ letterSpacing: 1 }}
            >
              of technology
            </div>
            <div
              className="tp-caption tp-resizeme z-index-10 font-xlight whitecolor text-capitalize"
              data-x="['center','center','center','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['40','40','0','0']"
              data-fontsize="['48','48','46','45']"
              data-whitespace="nowrap"
              data-responsive_offset="on"
              data-width="['none','none','none','none']"
              data-type="text"
              data-textalign="['center','center','center','center']"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-start={1000}
              data-splitin="none"
              data-splitout="none"
            >
              IT solutions that work
            </div>
            <div
              className="tp-caption"
              data-x="['center','center','center','center']"
              data-hoffset="['0','0','0','0']"
              data-y="['middle','middle','middle','middle']"
              data-voffset="['120','120','100','100']"
              data-textalign="['center','center','center','center']"
              data-whitespace="nowrap"
              data-type="button"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:1500;e:Power4.easeInOut;"
              data-transform_out="s:900;e:Power2.easeInOut;s:900;e:Power2.easeInOut;"
              data-start={2000}
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
            >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
  {/*Main Slider ends */}
  {/*Some Feature */}
  <section id="our-feature" className="single-feature padding">
    <div className="container">
      <div className="row d-flex align-items-center">
        <div
          className="col-lg-6 col-md-7 col-sm-7 text-sm-start text-center wow fadeInLeft"
          data-wow-delay="300ms"
        >
          <div className="heading-title mb-4">
            <h2 className="darkcolor font-normal bottom30">
              Lets take your <span className="defaultcolor">Business</span> to
              Next Level
            </h2>
          </div>
          <p className="bottom35">
          DG Techie is a leading website design company in Delhi NCR (INDIA). We provide web development solutions but also web services in delhi ncr. We have a team of highly skilled experts who are one of the best in the industry. In the digital era, it is "Not only #website, but promotion is equally important". We have many clients across India.
          </p>
          <p className="bottom35">
          Our team of passionate and innovative developers has proved their presence among the best website design company delhi ncr. Our philosophy is to develop websites that stands out with persuasive #design and easy navigation. We feel proud when our clients share their experience with us by stating and recommending us as "Best SEO Company in Delhi NCR" "Web Design Company Delhi NCR" "Best Web Development Company Delhi NCR" etc.
          <br/>
          For any kind of solutions like Web Designing in Delhi NCR, Web Development in Delhi NCR and SEO Services in Delhi NCR you may contact us and we'll respond to your queries on priority.

          </p>
        </div>
        <div
          className="col-lg-5 offset-lg-1 col-md-5 col-sm-5 wow fadeInRight"
          data-wow-delay="300ms"
        >
          <div className="image">
            <img alt="SEO" src="assets/images/blog-measonry2.jpg" />
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Some Feature ends*/}
  {/* WOrk Process*/}
  <section id="our-process" className="padding bgdark">
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-sm-12 text-center">
          <div
            className="heading-title whitecolor wow fadeInUp"
            data-wow-delay="300ms"
          >
            <span>The best IT experience starts here </span>
            <h2 className="font-normal">Our Services </h2>
          </div>
        </div>
      </div>
      <div className="row">
        <ul className="process-wrapp">
          <li className="whitecolor wow fadeIn" data-wow-delay="300ms">
            <span className="pro-step bottom20">01</span>
            <p className="fontbold bottom25">Website Development</p>
            <p className="mt-n2 mt-sm-0">
            DG Techie is an excellent Website Development Company in Delhi NCR, India. We thoroughly comprehend the objective of website development for each of our clients. Whether you are looking at improving sales of your physical products through online presence or selling digitals products or may be seeking it as a means of advertisement to your clients.
            </p>
          </li>
          <li className="whitecolor wow fadeIn" data-wow-delay="400ms">
            <span className="pro-step bottom20">02</span>
            <p className="fontbold bottom25">Website Designing</p>
            <p className="mt-n2 mt-sm-0">
            We are one of the best website designing companies in delhi. We deliver you what every successful business starts with, A uniquely developed Website. Being the top-rated Website Designing Company In Delhi NCR, we have hired and trained the best team to create freshly brewed designs to give an edge to your business over others in the market.
            </p>
          </li>
          <li className="whitecolor wow fadeIn" data-wow-delay="500ms">
            <span className="pro-step bottom20">03</span>
            <p className="fontbold bottom25">Digital Marketing</p>
            <p className="mt-n2 mt-sm-0">
            Digital Marketing is an umbrella term for the marketing of products and services by utilizing a number of digital technologies like the internet, intranet, mobile phones, or any other frequently used digital mediums and platforms. Digi Techie is the one of the most popular digital marketing agencies in Delhi NCR.
            </p>
          </li>
          <li className="whitecolor wow fadeIn" data-wow-delay="600ms">
            <span className="pro-step bottom20">04</span>
            <p className="fontbold bottom25">Search Engine Optimization</p>
            <p className="mt-n2 mt-sm-0">
            Search Engine Optimization (SEO) is a technical term for telling search engines like Google, Yahoo and Bing that you are now a part of the online world and you have some reliable products/services for search engine's users. We specialize in delivering an exhaustive range of seo services company in delhi guaranteed to work for your business.
            </p>
          </li>
          <li className="whitecolor wow fadeIn" data-wow-delay="700ms">
            <span className="pro-step bottom20">05</span>
            <p className="fontbold bottom25">Social Media Optimization</p>
            <p className="mt-n2 mt-sm-0">
            We offer Social Media Optimization Services that consist of result-oriented schemes to build a good persona for your brand in social media. Backed with acute industry experience and knowledge, we have capability to analyze your target visitors to verify the best way of boosting your business through social media websites.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </section>
  {/*WOrk Process ends*/}
  {/* Mobile Apps */}
  <section id="our-apps" className="padding">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-7 col-sm-12">
          <div
            className="heading-title bottom30 wow fadeInUp"
            data-wow-delay="300ms"
            style={{
              visibility: "visible",
              animationDelay: "300ms",
              animationName: "fadeInUp"
            }}
          >
            <span className="defaultcolor text-center text-md-start">
            Real-time computing made possible
            </span>
            <h2 className="darkcolor font-normal text-center text-md-start">
              Web App /Mobile App Development
            </h2>
          </div>
        </div>
        <div className="col-lg-6 col-md-5 col-sm-12">
          <p className="text-center text-md-start">
          Software development is all about creating software applications that are specifically designed to address the individual needs of your organization. We understand that every business is unique, and off-the-shelf software solutions often fall short in meeting specific goals and processes.
          </p>
        </div>
      </div>
      <div className="row d-flex align-items-center" id="app-feature">
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="text-center text-md-end">
            <div
              className="feature-item mt-3 wow fadeInLeft"
              data-wow-delay="300ms"
              style={{
                visibility: "visible",
                animationDelay: "300ms",
                animationName: "fadeInLeft"
              }}
            >
              <div className="icon">
                <i className="fas fa-cog" />
              </div>
              <div className="text">
                <h3 className="bottom15">
                  <span className="d-inline-block">Theme Options</span>
                </h3>
                <p>
                  We use  a wide range of customizable theme options to allow you to easily personalize your website. 
                  You can choose different color schemes and options.
                </p>
              </div>
            </div>
            <div
              className="feature-item mt-5 wow fadeInLeft"
              data-wow-delay="350ms"
              style={{
                visibility: "visible",
                animationDelay: "350ms",
                animationName: "fadeInLeft"
              }}
            >
              <div className="icon">
                <i className="fas fa-edit" />
              </div>
              <div className="text">
                <h3 className="bottom15">
                  <span className="d-inline-block">Customization</span>
                </h3>
                <p>
                DgTechie Software company, we specialize in providing top-notch custom software development services.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 text-center">
          <div className="app-image top30">
            <div className="app-slider-lock-btn" />
            <div className="app-slider-lock">
              <img src="assets/images/iphone-slide-lock.jpg" alt="" />
            </div>
            <div
              id="app-slider"
              className="owl-carousel owl-theme owl-loaded owl-drag"
            >
              <div className="owl-stage-outer">
                <div
                  className="owl-stage"
                  style={{
                    transform: "translate3d(-470px, 0px, 0px)",
                    transition: "all 0s ease 0s",
                    width: 1645
                  }}
                >
                  <div className="owl-item cloned" style={{ width: 235 }}>
                    <div className="item">
                      <img src="assets/images/iphone-slide2.jpg" alt="" />
                    </div>
                  </div>
                  <div className="owl-item cloned" style={{ width: 235 }}>
                    <div className="item">
                      <img src="assets/images/iphone-slide3.jpg" alt="" />
                    </div>
                  </div>
                  <div className="owl-item active" style={{ width: 235 }}>
                    <div className="item">
                      <img src="assets/images/iphone-slide1.jpg" alt="" />
                    </div>
                  </div>
                  <div className="owl-item" style={{ width: 235 }}>
                    <div className="item">
                      <img src="assets/images/iphone-slide2.jpg" alt="" />
                    </div>
                  </div>
                  <div className="owl-item" style={{ width: 235 }}>
                    <div className="item">
                      <img src="assets/images/iphone-slide3.jpg" alt="" />
                    </div>
                  </div>
                  <div className="owl-item cloned" style={{ width: 235 }}>
                    <div className="item">
                      <img src="assets/images/iphone-slide1.jpg" alt="" />
                    </div>
                  </div>
                  <div className="owl-item cloned" style={{ width: 235 }}>
                    <div className="item">
                      <img src="assets/images/iphone-slide2.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-nav disabled">
                <button type="button" role="presentation" className="owl-prev">
                  <span aria-label="Previous">‹</span>
                </button>
                <button type="button" role="presentation" className="owl-next">
                  <span aria-label="Next">›</span>
                </button>
              </div>
              <div className="owl-dots disabled" />
            </div>
            <img src="assets/images/iphone.png" alt="image" />
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="text-center text-md-start">
            <div
              className="feature-item mt-3 wow fadeInRight"
              data-wow-delay="300ms"
              style={{
                visibility: "visible",
                animationDelay: "300ms",
                animationName: "fadeInRight"
              }}
            >
              <div className="icon">
                <i className="fas fa-code" />
              </div>
              <div className="text">
                <h3 className="bottom15">
                  <span className="d-inline-block">Powerful Code</span>
                </h3>
                <p>
                Digi Techie is one of the best mobile application development companies in Delhi NCR, offering top notch mobile application development services.
                </p>
              </div>
            </div>
            <div
              className="feature-item mt-5 wow fadeInRight"
              data-wow-delay="350ms"
              style={{
                visibility: "visible",
                animationDelay: "350ms",
                animationName: "fadeInRight"
              }}
            >
              <div className="icon">
                <i className="far fa-folder-open" />
              </div>
              <div className="text">
                <h3 className="bottom15">
                  <span className="d-inline-block">Documentation </span>
                </h3>
                <p>
                  Our Web apps amd Mobile Apps are well documented and also give  you access to the source code.
                  Comprehensive documentation with examples for each component.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Mobile Apps ends*/}
  {/* Counters */}
  <section id="bg-counters" className="padding bg-counters parallax">
    <div className="container">
      <div className="row align-items-center text-center">
        <div className="col-lg-4 col-md-4 col-sm-4 bottom10">
          <div className="counters whitecolor  top10 bottom10">
            <span
              className="count_nums font-light"
              data-to={2016}
              data-speed={2500}
            >
              {" "}
            </span>
          </div>
          <h3 className="font-light whitecolor top20">Since We Started</h3>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4">
          <p className="whitecolor top20 bottom20 font-light title">
          For any kind of solutions like Web Designing in Delhi NCR, Web Development in Delhi NCR and SEO Services in Delhi NCR you may contact us and we'll respond to your queries on priority.

          </p>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4 bottom10">
          <div className="counters whitecolor top10 bottom10">
            <span
              className="count_nums font-light"
              data-to={400}
              data-speed={2500}
            >
              {" "}
            </span>
          </div>
          <h3 className="font-light whitecolor top20">Since We Started</h3>
        </div>
      </div>
    </div>
  </section>
  {/* Counters ends*/}
  
  {/*Pricing Start*/}
  <section id="pricing" className="padding bglight">
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-sm-12 text-center">
          <div
            className="heading-title darkcolor wow fadeInUp"
            data-wow-delay="300ms"
          >
            <span className="defaultcolor">
            For That Deep Down Body It
            </span>
            <h2 className="font-normal bottom30"> Swing Your It. </h2>
          </div>
        </div>
        
      </div>
      <div className="owl-carousel owl-theme no-dots" id="price-slider">
        <div className="item">
          <div className="col-md-12">
            <div
              className="pricing-item wow fadeInUp animated"
              data-wow-delay="300ms"
              data-sale={60}
            >
              <h3 className="font-light darkcolor">Why Choose Us</h3>
              <p className="bottom30">We do website designing energetically. We follow guidelines on developing Search-Engine Friendly Websites and have brainstorming sessions to come up with a unique design for each of our clients based on their existing customer base including the targeted ones. Being the top rated website designing company in delhi, Our proficient developers are the best in advertising customized touch to your Business Website</p>
              
            </div>
          </div>
        </div>
        <div className="item">
          <div className="col-md-12">
            <div
              className="pricing-item wow fadeInUp animated"
              data-wow-delay="380ms"
              data-sale={40}
            >
              <h3 className="font-light darkcolor">Our Mission</h3>
              <p className="bottom30">Our mission is to generate youth employment in India, empower Indian Businesses by establishing their web presence, export web designing, digital marketing & branding services to business worldwide to generate foreign currency & make our country a Digital India !!</p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="col-md-12">
            <div
              className="pricing-item wow fadeInUp animated "
              data-wow-delay="460ms"
              data-sale={30}
            >
              <h3 className="font-light darkcolor">Our Vision</h3>
              <p className="bottom30">Our vision is to strengthen web presence of SMEs, Start-ups, Corporates & Organisations on the internet with their own website. Most of the businesses are advertising on B2B Portals & struggling to generate business through distributed enquiries. Our vision is to promote them directly on Google with their own website & establish their brand image.</p>
              
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </section>
  {/*Pricing ends*/}
  
  
  {/* Testimonials */}
  <section id="our-testimonial" className="bglight padding_bottom">
    <div className="parallax page-header testimonial-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-6 col-md-12 text-center text-lg-end">
            <div
              className="heading-title wow fadeInUp padding_testi"
              data-wow-delay="300ms"
            >
              <span className="whitecolor">Getting ahead of the technology</span>
              <h2 className="whitecolor font-normal">What People Say</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="owl-carousel" id="testimonial-slider">
        {/*item 1*/}
        <div className="item testi-box">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-12 text-center">
              <div className="testimonial-round d-inline-block">
                <img src="assets/images/awesome-feature.png" alt="" />
              </div>
              <h4 className="defaultcolor font-light top15">
                <a href="#.">Himshikha Anand</a>
              </h4>
              <p>Delhi India</p>
            </div>
            <div className="col-lg-6 offset-lg-2 col-md-10 offset-md-1 text-lg-start text-center">
              <p className="bottom15 top90">
              Love the commitment project managers along with complete team work with to deliver within defined timelines. Whole team understands criticality of things and impact any small mistake can have on overall business. Would definitely like to work with you guys again.
              </p>
              
            </div>
          </div>
        </div>
        {/*item 2*/}
        <div className="item testi-box">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-12 text-center">
              <div className="testimonial-round d-inline-block">
                <img src="assets/images/awesome-feature.png" alt="" />
              </div>
              <h4 className="defaultcolor font-light top15">
                <a href="#.">Gaurav Jain</a>
              </h4>
              <p>India</p>
            </div>
            <div className="col-lg-6 offset-lg-2 col-md-10 offset-md-1 text-lg-start text-center">
              <p className="bottom15 top90">
              People here deliver, ON TIME. I had a time critical assignment to take care of. These guys did an amazing job, i am very satisfied with the service quality and the money charged. Keep up the good work guys!
              </p>
              
            </div>
          </div>
        </div>
        {/*item 3*/}
        <div className="item testi-box">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-12 text-center">
              <div className="testimonial-round d-inline-block">
                <img src="assets/images/awesome-feature.png" alt="" />
              </div>
              <h4 className="defaultcolor font-light top15">
                <a href="#.">Amerpreet (Astrologer)</a>
              </h4>
              <p>Australia</p>
            </div>
            <div className="col-lg-6 offset-lg-2 col-md-10 offset-md-1 text-lg-start text-center">
              <p className="bottom15 top90">
                DgTechie have been very professional, prompt and thorough whilst working on our application. With an industry experience best mobile App Development company offer a plethora of client centric services by enabling customers achieve competitive advantage through flexible and next generation global delivery models.
              </p>
              
            </div>
          </div>
        </div>
        {/*item 4*/}
        <div className="item testi-box">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-12 text-center">
              <div className="testimonial-round d-inline-block">
                <img src="assets/images/awesome-feature.png" alt="" />
              </div>
              <h4 className="defaultcolor font-light top15">
                <a href="#.">Anil</a>
              </h4>
              <p>India</p>
            </div>
            <div className="col-lg-6 offset-lg-2 col-md-10 offset-md-1 text-lg-start text-center">
              <p className="bottom15 top90">
              Its been a very good experience working here.Best for any fresher to startup her\his career. Environment is really well. All seniors are always ready to help. Besides work, parties and fun is also here.
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Testimonials Ends*/}
  {/*video part section*/}
  <section className="bglight">
    <h2 className="d-none">Visioning a high-tech future</h2>
    <div className="container-fluid bg-white">
      <div className="row align-items-center">
        <div className="col-md-6 col-sm-12 p-0 col-video">
          <div className="row mx-0">
            <div className="col-md-10 offset-md-1 offset-sm-0 col-sm-12">
              <div className="video-content-setting center-block text-center text-sm-start">
                <h2 className="darkcolor font-normal text-capitalize mb-3">
                  a Web Designing/ Development Company in Delhi NCR
                </h2>
                <p className="darkcolor">
                Being the best web designing company in Delhi we take utmost care for understanding the specific requirements that a client has. Most of the other service providers won’t care for it, and just handover the website as they want to build. But this is not the case when you choose us, we will be giving you detailed information at each step of the website development. We have an expert team that will be ready to assist you with any doubts that you might have. Please keep in mind that just a website design doesn’t stand alone when it comes to marketing strategies. There are several other aspects that we shall handle for you so that you have significant gains for the business goals that you have in your mind.

                </p>
                <p className="darkcolor">
                As we are offering website designing services in Delhi that have a key focus on SEO, keyword promotion and blog promotions with a lot more, you will never be without a clear path that needs to be followed and there will be an improvement in business overall.

                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 p-0 col-video video-bg">
          <div className="image split-blog-scale transition-3">
            <img
              alt="stats"
              src="assets/images/bg-blog-detail-header.jpg"
              className="video-img-setting "
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*video part section end*/}
  
  {/*contact us*/}
  <section id="contact" className="bglight padding position-relative noshadow">
    <div className="container whitebox">
      <div className="widget py-5">
        <div className="row">
          <div
            className="col-md-12 text-center wow fadeIn mt-n3"
            data-wow-delay="300ms"
            style={{
              visibility: "visible",
              animationDelay: "300ms",
              animationName: "fadeIn"
            }}
          >
            <div
              className="heading-title bottom25 darkcolor wow fadeInUp"
              data-wow-delay="300ms"
            >
              <h2 className="font-normal darkcolor"> Contact Us </h2>
            </div>
            
          </div>
          <div className="col-md-12 col-sm-12 order-sm-2 text-center">
            <div className="contact-meta px-2">
              <div className="heading-title">
                <h3 className="defaultcolor mb-3">Digital Techie</h3>
                <h4 className="darkcolor font-normal mb-4">
                  <span className="d-none d-md-inline-block">Our</span> Office{" "}
                  <span className="d-none d-md-inline-block">in India</span>
                </h4>
              </div>
              <p className="bottom10">
                Address: Digital Techie, First Floor, Kakroi Rd, opp. Sec-23, Sector 24, Sonipat, Haryana 131001
              </p>
              <p className="bottom10">+91-70153-77898</p>
              <p className="bottom10">
                <a href="mailto:dgtechie.india@gmail.com">
                dgtechie.india@gmail.com
                </a>
              </p>
              <p className="bottom10">Mon-Fri: 10am-6pm</p>
              <ul
                className="social-icons mt-4 mb-4 mb-sm-0 wow fadeInUp"
                data-wow-delay="300ms"
                style={{
                  visibility: "visible",
                  animationDelay: "300ms",
                  animationName: "fadeInUp"
                }}
              >
                <li>
                  <a href="https://www.facebook.com/dgtechie"  target="_blank"  rel="noreferrer">
                    <i className="fab fa-facebook-f" />{" "}
                  </a>{" "}
                </li>
                <li>
                  <a href="https://twitter.com/dgtechieindia" target="_blank"  rel="noreferrer">
                    <i className="fab fa-twitter" />{" "}
                  </a>{" "}
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/dgtechie" target="_blank"  rel="noreferrer">
                    <i className="fab fa-linkedin-in" />{" "}
                  </a>{" "}
                </li>
                <li>
                  <a href="https://www.instagram.com/digital.techie" target="_blank"  rel="noreferrer">
                    <i className="fab fa-instagram" />{" "}
                  </a>{" "}
                </li>
                <li>
                  <a href="https://wa.me/+91917015377898" target="_blank"  rel="noreferrer">
                    <i className="fab fa-whatsapp" />{" "}
                  </a>{" "}
                </li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-3">
          <div className="widget text-center top60 w-100">
            <div className="contact-box">
              <span className="icon-contact defaultcolor">
                <i className="fas fa-mobile-alt" />
              </span>
              <p className="bottom0">
                <a href="tel:+917015377898">+91-70153-77898</a>
              </p>
              <p className="d-block">
                <a href="tel:+919996229555">+91-9996-229-555</a>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <div className="widget text-center top60 w-100">
            <div className="contact-box">
              <span className="icon-contact defaultcolor">
                <i className="fas fa-map-marker-alt" />
              </span>
              <p className="bottom0">Digital Techie</p>
              <p className="d-block"> HNO 1899, Sector 23, Sonipat, Haryana 131001 </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <div className="widget text-center top60 w-100">
            <div className="contact-box">
              <span className="icon-contact defaultcolor">
                <i className="far fa-envelope" />
              </span>
              <p className="bottom0">
                <a href="mailto:dgtechie.india@gmail.com">dgtechie.india@gmail.com</a>
              </p>
              <p className="d-block">
                <a href="mailto:mydgtech@gmail.com">mydgtech@gmail.com</a>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <div className="widget text-center top60 w-100">
            <div className="contact-box">
              <span className="icon-contact defaultcolor">
                <i className="far fa-clock" />
              </span>
              <p className="bottom15">
                {new Date().toLocaleDateString()} <span className="d-block">{new Date().toLocaleTimeString()}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <footer id="site-footer" className=" bgdark padding_top">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div className="footer_panel padding_bottom_half bottom20">
            <a href="/" className="footer_logo bottom25">
              <img src="assets/img/logo.png" alt="MegaOne" />
            </a>
            <p className="whitecolor bottom25">
            We feel proud when our clients share their experience with us by stating and recommending us as "Best SEO Company in Delhi NCR" "Web Design Company Delhi NCR" "Best Web Development Company Delhi NCR" etc.
            </p>
            <div className="d-table w-100 address-item whitecolor bottom25">
              <span className="d-table-cell align-middle">
                <i className="fas fa-mobile-alt" />
              </span>
              <p className="d-table-cell align-middle bottom0">
                +91 - 70153-77898
                <a className="d-block" href="mailto:dgtechie.india@gmail.com">
                  dgtechie.india@gmail.com
                </a>
              </p>
            </div>
            <ul
              className="social-icons white wow fadeInUp"
              data-wow-delay="300ms"
            >
              <li>
                <a href="https://www.facebook.com/dgtechie" className="facebook" target="_blank"  rel="noreferrer">
                  <i className="fab fa-facebook-f" />{" "}
                </a>
              </li>
              <li>
                <a href="https://twitter.com/dgtechieindia" className="twitter" target="_blank"  rel="noreferrer" >
                  <i className="fab fa-twitter" />{" "}
                </a>{" "}
              </li>
              <li>
                <a href="https://www.linkedin.com/company/dgtechie" className="linkedin" target="_blank"  rel="noreferrer">
                  <i className="fab fa-linkedin-in" />{" "}
                </a>{" "}
              </li>
              <li>
                <a href="https://www.instagram.com/digital.techie" className="insta" target="_blank"  rel="noreferrer">
                  <i className="fab fa-instagram" />{" "}
                </a>{" "}
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="footer_panel padding_bottom_half bottom20 ps-0 ps-lg-5">
            <h3 className="whitecolor bottom25">Our Services</h3>
            <ul className="links">
              <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#our-process">About Us</a>
              </li>
              <li>
                <a href="#our-apps">Services</a>
              </li>
              <li>
                <a href="#our-testimonial">Business Planning</a>
              </li>
              <li>
                <a href="#contact">Contact Us</a>
              </li>
              
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="footer_panel padding_bottom_half bottom20">
            <h3 className="whitecolor bottom25">Business hours</h3>
            <p className="whitecolor bottom25">
              Our support available to help you 24 hours a day, seven days week
            </p>
            <ul className="hours_links whitecolor">
              <li>
                <span>Monday-Friday:</span> <span>10.00-18.00</span>
              </li>
              <li>
                <span>Saturday:</span> <span>10:00-15:00</span>
              </li>
              <li>
                <span>Sunday:</span> <span>10:00-15:00</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
  {/*Footer ends*/}
</>

    </div>
  );
}

export default App;
